import * as React from "react";
import { storeUrl } from "../utils/detect-os";
import splitbee from '@splitbee/web';

export default function RedirectToStore() {
  React.useEffect(() => {
    const url = storeUrl();
    if(url.indexOf('apple')) {
      splitbee.track('download-ios');
    } else {
      splitbee.track('download-android');
    }
    window.location.replace(url);
  })
  return (
    <div>
    </div>
  );
}
